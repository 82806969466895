import { combineReducers } from 'redux'
import auth from './auth'
import search from './search'
import category from './category'
import brand from './brand'
import plan from './plan'
import searchSuggestion from './searchsuggestions'
import searchResult from './searchresult'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        auth,
        search,
        category,
        brand,
        plan,
        searchSuggestion,
        searchResult,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
