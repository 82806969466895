import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    planList: [],
    error: [],
    planCreated: false,
};
const planSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.planList = {};
            state.error = {};
        },
        planList: (state, action) => {
            state.loading = false;
            state.planList = action.payload.list;
            state.error = {}
        },
        savePlan: (state) => {
            state.planCreated = true;
            state.loading = false;
            state.error = {}
        },
        handleError: (state) => {
            state.loading = true;
            state.planList = {};
            state.error = {code: 500, message: "Something went wrong!"};
        }
    }
})

export const { setLoading, planList, savePlan, handleError } = planSlice.actions;
export default planSlice.reducer;