import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    searchList: null,
    topTrendList: [],
    topImagePath: '',
    error: {}
}

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.searchList = {};
            state.error = {};
        },
        searchList: (state, action) => {
            state.loading = false;
            state.searchList = action.payload.list;
            state.error = {}
        },
        topTrendList: (state, action) => {
            state.loading = false;
            state.topTrendList = action.payload.list.top;
            state.topImagePath = action.payload.list.path;
            state.error = {}
        },
        handleError: (state) => {
            state.loading = true;
            state.searchList = {};
            state.error = {code: 500, message: "Something went wrong!"};
        }
    }
});

export const { setLoading, searchList, topTrendList, handleError } = searchSlice.actions;
export default searchSlice.reducer;