import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    brandList: [],
    error: []
};
const brandSlice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.brandList = {};
            state.error = {};
        },
        brandList: (state, action) => {
            state.loading = false;
            state.brandList = action.payload.list;
            state.error = {}
        },
        handleError: (state) => {
            state.loading = true;
            state.brandList = {};
            state.error = {code: 500, message: "Something went wrong!"};
        }
    }
})

export const { setLoading, brandList, handleError } = brandSlice.actions;
export default brandSlice.reducer;