import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    suggestionList: null,
    suggestionPath: '',
    error: {}
}

const searchSuggestionSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.suggestionList = {};
            state.error = {};
        },
        suggestionList: (state, action) => {
            state.loading = false;
            state.suggestionList = action.payload.list.pl;
            state.suggestionPath = action.payload.list.path;
            state.error = {}
        },
        handleError: (state) => {
            state.loading = true;
            state.suggestionList = {};
            state.error = {code: 500, message: "Something went wrong!"};
        }
    }
});

export const { setLoading, suggestionList, handleError } = searchSuggestionSlice.actions;
export default searchSuggestionSlice.reducer;