import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: {
        location: false,
        list: false,
    },
    locationList: [],
    searchResultList: [],
    error: []
};
const searchResultSlice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        setLocationLoading: (state) => {
            state.loading.location = true;
            state.locationList = {};
            state.error = {};
        },
        locationList: (state, action) => {
            state.loading.location = false;
            state.locationList = action.payload.list;
            state.error = {}
        },
        setListLoading: (state) => {
            state.loading.list = true;
            state.searchResultList = {};
            state.error = {};
        },
        searchResultList: (state, action) => {
            state.loading.list = false;
            state.searchResultList = action.payload.list.data;
            state.error = {}
        },
        handleError: (state) => {
            state.loading = true;
            state.locationList = {};
            state.error = {code: 500, message: "Something went wrong!"};
        }
    }
})

export const { setLocationLoading, locationList, setListLoading, searchResultList, handleError } = searchResultSlice.actions;
export default searchResultSlice.reducer;